const theme = {
  // add what you want to overwrite in defaut theme
  toast: {
    position: 'top',
    margin: { top: '20px', horizontal: 'xxlarge' },
    colors: {
      success: 'status-ok',
      warning: 'status-warning',
      error: 'status-error',
      info: 'info',
    },
  },
};

export default theme;
