import { format } from 'date-fns';

async function extractHeader(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function () {
      let allTextLines = reader?.result?.split(/\r\n|\n/);
      let headerTextLines = allTextLines[0].replace(/["]/g, '');
      let headers = headerTextLines
        .toLowerCase()
        .split(',')
        .map(i => i.trim());
      resolve(headers);
      reject(false);
    };
    reader.readAsText(file);
  });
}

const getBackDate = (numberOfDays, customFormat) => {
  var d = new Date();
  d.setDate(d.getDate() - numberOfDays);
  return format(d, customFormat);
};

const getDateRangeOptions = customFormat => {
  let today = new Date();
  let yesterday = format(new Date(today.setDate(today.getDate() - 1)), customFormat);
  return [
    { label: 'Last 7 days', value: [getBackDate(7, customFormat), yesterday] },
    { label: 'Last 15 days', value: [getBackDate(15, customFormat), yesterday] },
    { label: 'Last 30 days', value: [getBackDate(30, customFormat), yesterday] },
    { label: 'Custom Date Range', value: 'VIEW_DATE_PICKER' },
  ];
};

export { extractHeader, getDateRangeOptions };
