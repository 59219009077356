import konectApi from 'konect/gateways/konect-api';

async function validateToken(eventEmitter, plugin_profile_pk) {
  try {
    const {
      plugin_master: { pk },
    } = await konectApi.getMyDetails(plugin_profile_pk);
    eventEmitter.emit('TOKEN_VALIDATION_SUCCESS', pk);
  } catch (error) {
    eventEmitter.emit('TOKEN_VALIDATION_FAILURE');
  }
}

export { validateToken };
