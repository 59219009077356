import React, { useMemo, useState } from 'react';
import { Box, Text, Button } from 'grommet';
import PropTypes from 'prop-types';
import { Update, InstallOption } from 'grommet-icons';

import HourlyTable from './HourlyTable';
import InventoryGraph from './InventoryGraph';
import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';

const Dashboard = ({
  eventEmitter,
  subscriberPk,
  getInventorySyncData,
  handleRetry,
  getHistory,
  sourceProfileId,
  handleCustomExport,
}) => {
  //removed plugin_subscriber call, instead we use value of name from organisations as pk or retailer_id.
  const [pk, setPk] = useState(sourceProfileId);
  const [inventoryLoading, setInventoryLoading] = useState(false);
  const grandEmitter = useMemo(() => getGrandEmitter(), []);
  const handleRefresh = () => {
    //   // a jugaad or smart way to refresh page
    //   // it changes the pk for 200ms due to which child components gets refresh as they think pk got changed
    setPk(null);
    setInventoryLoading(true);
    window.setTimeout(() => setPk(pk), 200);
  };

  return (
    <>
      <Box fill="horizontal" align="end">
        {inventoryLoading && <Button icon={<Update size="medium" />} disabled />}
        {!inventoryLoading && (
          <Button tip="Refresh" icon={<Update size="medium" />} hoverIndicator onClick={handleRefresh} />
        )}
      </Box>
      <Box direction="row-responsive" pad={{ horizontal: 'small' }}>
        <Box fill="horizontal" alignSelf="start">
          <Text size="xlarge" weight="bold">
            Sync Statistics (Last 30 Days)
          </Text>
        </Box>
      </Box>
      <Box pad="small">
        <InventoryGraph
          eventEmitter={eventEmitter}
          pk={pk}
          setInventoryLoading={setInventoryLoading}
          subscriberPk={subscriberPk}
          getInventorySyncData={getInventorySyncData}
        />
      </Box>
      <Box direction="row-responsive" pad={{ horizontal: 'small', top: 'large' }}>
        <Box fill="horizontal" alignSelf="start">
          <Text size="xlarge" weight="bold">
            History
          </Text>
        </Box>

        <Button
          align="end"
          size="medium"
          label="Export"
          icon={<InstallOption alignSelf="End" size="small" />}
          primary
          onClick={() => {
            grandEmitter.emit('CUSTOM_EXPORT');
          }}
        />
      </Box>
      <Box pad="small">
        <HourlyTable
          eventEmitter={eventEmitter}
          handleRetry={handleRetry}
          getHistory={getHistory}
          handleCustomExport={handleCustomExport}
        />
      </Box>
    </>
  );
};

Dashboard.propTypes = {
  organisations: PropTypes.array,
  subscriberPk: PropTypes.any,
  eventEmitter: PropTypes.any,
  handleRetry: PropTypes.func,
  getHistory: PropTypes.func,
  getInventorySyncData: PropTypes.func,
  sourceProfileId: PropTypes.any,
  handleCustomExport: PropTypes.func,
};

export default Dashboard;
