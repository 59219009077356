import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Box } from 'grommet';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';

import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import useQuery from 'granite-admin/utils/useQuery';
import { useToast } from 'granite-admin/core/components/Toast';
import EventEmitter from 'granite-admin/utils/event-emitter';
import Loader from 'granite-admin/core/components/Loader';
import Tabs from 'granite-admin/core/components/Tabs';
import ErrorPage from 'konect-admin/components/ErrorPage';

import { getInventorySyncData, handleRetry } from 'dashboard/controllers/dashboard';
import Dashboard from 'common/Dashboard/Dashboard';
import konectApi from 'konect/gateways/konect-api';
import Configuration from 'pages/sentral/configuration';
import { validateToken } from 'konect/controllers';

const SentralDashboard = ({ selectedOrganisation }) => {
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const userProfile = useSelector(({ accounts }) => accounts.user.profile);
  const [sourceProfileId, setSourceProfileId] = useState(null);
  const { successToast, errorToast } = useToast();
  const [validation, setValidation] = useState('inProgress');

  const { query } = useQuery();

  const getHistory = useCallback(
    async data => {
      const { range, ...rest } = data;
      const params = {
        subscriber_profile_pk: query.profile_pk ? +query.profile_pk : +userProfile.plugin_profile_pk,
        create_start_date: data.range?.split(' - ')[0],
        create_end_date: data.range?.split(' - ')[1],
        ...rest,
      };
      return await konectApi.getHistory(params);
    },
    [query, userProfile],
  );

  const handleCustomExport = useCallback(
    async tableUrlParams => {
      try {
        const params = {
          subscriber_profile_pk: userProfile.plugin_profile_pk,
          organisation_id: selectedOrganisation.pk,
          create_end_date: tableUrlParams?.range?.split(' - ')[1],
          create_start_date: tableUrlParams?.range?.split(' - ')[0],
        };
        await konectApi.getExport(params);
        successToast('File is being generated, and will be sent on your email!');
      } catch (e) {
        errorToast(e.error?.title || 'Something went wrong');
      }
    },
    [errorToast, userProfile, selectedOrganisation, successToast],
  );

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'TOKEN_VALIDATION_SUCCESS':
          setSourceProfileId(event.data);
          setValidation('pass');
          break;
        case 'TOKEN_VALIDATION_FAILURE':
          setValidation('fail');
          break;
        default:
          break;
      }
    });
    validateToken(eventEmitter, userProfile.plugin_profile_pk);
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast, successToast, userProfile.plugin_profile_pk, userProfile.plugin_code]);

  const tabs = [
    {
      content: (
        <Dashboard
          eventEmitter={eventEmitter}
          subscriberPk={query.profile_pk ? query.profile_pk : userProfile.plugin_profile_pk}
          getInventorySyncData={getInventorySyncData}
          handleRetry={handleRetry}
          getHistory={getHistory}
          sourceProfileId={sourceProfileId}
          handleCustomExport={handleCustomExport}
        />
      ),
      title: 'Dashboard',
      path: 'graph',
    },
    {
      content: (
        <Configuration eventEmitter={eventEmitter} sourceProfileId={sourceProfileId} userProfile={userProfile} />
      ),
      title: 'Configuration',
      path: 'configuration',
    },
  ];

  return (
    <>
      {validation === 'inProgress' && (
        <Box fill align="center" justify="center">
          <Loader />
        </Box>
      )}
      {validation === 'pass' && <Tabs tabs={tabs} />}
      {validation === 'fail' && <ErrorPage />}
    </>
  );
};

SentralDashboard.propTypes = {
  selectedOrganisation: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
});

export default connect(mapStateToProps)(SentralDashboard);
