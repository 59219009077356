import React, { useEffect, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

import Loader from 'granite-admin/core/components/Loader';
import konectApi from 'konect/gateways/konect-api';
import CommonForm from './component/CommonForm';

const Configuration = ({ userProfile, sourceProfileId }) => {
  const [refreshStatus, setRefreshStatus] = useState('SUCCESS');
  const [preferences, setPreferences] = useState(null);
  const [loader, setLoader] = useState(true);

  const fetch = useCallback(async () => {
    try {
      const payload = {
        params: { subscriber_profile_pk: userProfile?.plugin_profile_pk },
      };
      const result = await Promise.all([konectApi.getMySettings(payload), konectApi.profileProcess(payload)]);
      setPreferences(result[0]);
      setRefreshStatus(result[1][0]?.last_executed_result?.status);
      setLoader(false);
    } catch (err) {
      setPreferences(null);
      setLoader(false);
    }
  }, [userProfile]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const initialValues = useMemo(() => {
    const iv = {
      api_key: '',
      base_url: '',
      tenant: [],
      campus: [],
      student_number: '',
    };

    if (preferences) {
      preferences.forEach(preference => {
        if (preference.code === 'SENTRAL_API_KEY') iv.api_key = preference.value;
        else if (preference.code === 'SENTRAL_BASE_URL') iv.base_url = preference.value;
        else if (preference.code === 'SENTRAL_STUDENT_NUMBER') iv.student_number = preference.value;
        else if (preference.code === 'SENTRAL_TENANT_NAME') {
          iv.tenant.push({
            tenant: preference.value,
            pk: preference.pk,
          });
          if (preference?.extra_data?.campus_id) {
            iv.campus.push({
              campus_name: preference?.extra_data?.campus_name,
              campus_id: preference?.extra_data?.campus_id,
            });
          }
        }
      });
    }
    return iv;
  }, [preferences]);

  return (
    <>
      {loader ? (
        <Box fill align="center" justify="center">
          <Loader />
        </Box>
      ) : preferences && preferences.length ? (
        <CommonForm
          initialValues={initialValues}
          comp={'configuration'}
          source_profile_id={sourceProfileId}
          refreshStatus={refreshStatus}
          setRefreshStatus={setRefreshStatus}
          plugin_profile_pk={userProfile.plugin_profile_pk}
          fetch={fetch}
        />
      ) : (
        <Text weight="bold" textAlign="center" wordBreak="normal" size="xlarge">
          Nothing to configure here.
        </Text>
      )}
    </>
  );
};

Configuration.propTypes = {
  eventEmitter: PropTypes.object,
  sourceProfileId: PropTypes.any,
  userProfile: PropTypes.object,
};

export default Configuration;
