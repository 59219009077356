import dashboardApi from 'dashboard/gateways/dashboard-api';
import konectApi from 'konect/gateways/konect-api';
import sentralApi from 'sentral/gateways/sentral-api';
/* eslint-disable */

async function validateToken(eventEmitter, plugin_profile_pk) {
  try {
    const {
      plugin_master: { pk },
    } = await konectApi.getMyDetails(plugin_profile_pk);
    eventEmitter.emit('TOKEN_VALIDATION_SUCCESS', pk);
  } catch (error) {
    eventEmitter.emit('TOKEN_VALIDATION_FAILURE');
  }
}

async function getSubscriberPk(eventEmitter, pluginCode) {
  try {
    const payload = { plugin_code: pluginCode };
    const { pk } = await konectApi.getSubscriberPk(payload);
    eventEmitter.emit('SUBSCRIBER_PK_FETCHED', pk);
  } catch (error) {
    eventEmitter.emit('FAILED_TO_FETCH_PK');
  }
}

async function getPluginProfilePk(eventEmitter, plugin_profile_pk) {
  try {
    const { source_profile_id } = await konectApi.getMyDetails(plugin_profile_pk);
    eventEmitter.emit('PLUGIN_PROFILE_PK_SUCCESS', source_profile_id);
  } catch (err) {
    eventEmitter.emit('PLUGIN_PROFILE_PK_FAILURE');
  }
}

async function getInventorySyncData(eventEmitter, data) {
  try {
    const { date, pk, subscriberPk } = data;
    var formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    const params = {
      current_date: formattedDate,
      retailer_id: pk,
      subscriber_profile_pk: +subscriberPk,
    };
    const { list } = await dashboardApi.getInventorySyncData(params);
    eventEmitter.emit('INVENTORY_DATA_FETCH_SUCCESS', list);
  } catch (error) {
    eventEmitter.emit('INVENTORY_DATA_FETCH_FAILURE');
    console.log('error', error);
  }
}

async function handleRetry(id, retryEventEmitter) {
  try {
    const params = { source_object_pk: id };
    const response = await sentralApi.handleRetry(params);
    retryEventEmitter.emit('REFRESH_SUCCESSS', response);
  } catch (error) {
    retryEventEmitter.emit('FAILED_TO_REFRESH');
  }
}

export { validateToken, getSubscriberPk, getPluginProfilePk, getInventorySyncData, handleRetry };
