import { lazy } from 'react';

import ErrorPage from 'konect-admin/components/ErrorPage';
import ExpiredPage from 'konect-admin/components/ExpiredPage';
import SentralSuccess from 'pages/sentral/dashboard';

const Sentral = lazy(() => import('pages/sentral'));
const SpreadSheet = lazy(() => import('pages/spreadsheet'));
const SSO = lazy(() => import('pages/sso'));
const DataMigration = lazy(() => import('pages/dataMigration'));

export default [
  {
    path: '/login', // This is the landing URL if API throws 401
    component: ExpiredPage,
  },
  {
    path: '/error',
    component: ErrorPage,
  },
  {
    path: '/plugins/edit',
    private: true,
    component: SpreadSheet,
    layout: 'DashboardLayout',
    breadcrumbPath: [{ label: 'Manage' }, { label: 'Plugins', path: '/plugins' }],
    heading: {
      title: 'Plugin Details',
      subTitle: 'View and manage plugin details',
    },
  },
  {
    path: '/sentral',
    exact: true,
    private: true,
    component: Sentral,
    layout: 'DashboardLayout',
    // breadcrumbPath: [{ label: 'Manage' }, { label: 'Lightspeed', path: '/plugins/lightspeed' }],
    // heading: {
    //   title: 'Lightspeed',
    //   subTitle: 'View and manage lightspeed details',
    // },
  },
  {
    path: '/sentral/success',
    // exact: true,
    private: true,
    component: SentralSuccess,
    // layout: 'DashboardLayout',
    // breadcrumbPath: [{ label: 'Manage' }, { label: 'Lightspeed', path: '/plugins/lightspeed' }],
    // heading: {
    //   title: 'Lightspeed',
    //   subTitle: 'View and manage lightspeed details',
    // },
  },
  {
    path: '/sso/success',
    private: true,
    exact: true,
    component: SSO,
  },
  {
    path: '/datamigration-activate',
    private: true,
    component: DataMigration,
    shouldFetchHelpTexts: false,
    shouldFetchPermissions: false,
  },
];
