// Lib Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Loader from 'granite-admin/core/components/DotLoader';

const renderCustomAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-30)" scaleToFit="true" fontSize={15}>
        {payload.value}
      </text>
    </g>
  );
};

const InventoryGraph = ({ eventEmitter, pk, setInventoryLoading, subscriberPk, getInventorySyncData }) => {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (pk) {
      setIsLoading(true);
      setInventoryLoading(true);
      getInventorySyncData(eventEmitter, { date: new Date(), pk, subscriberPk });
    }
  }, [eventEmitter, pk, setInventoryLoading, subscriberPk, setIsLoading, getInventorySyncData]);

  React.useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'INVENTORY_DATA_FETCH_SUCCESS':
          setData(event.data);
          setIsLoading(false);
          setInventoryLoading(false);
          break;
        case 'INVENTORY_DATA_FETCH_FAILURE':
          setIsLoading(false);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, setInventoryLoading, setIsLoading]);

  return (
    <Box alignContent="center">
      {isLoading ? (
        <Box align="center" height="100%" justify="center" pad="small" direction="row" size="medium">
          <Loader />
        </Box>
      ) : (
        <Box height="medium" fill="horizontal">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="hourlyProgress" padding={{ left: 10 }} tick={renderCustomAxisTick} height={50} />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" height="70" wrapperStyle={{ margin: -20 }} />
              <Bar dataKey="failed" stackId="a" fill="#FF0000" name="Failed" />
              <Bar dataKey="inProgress" stackId="a" fill="#0000FF" name="In Progress" />
              <Bar dataKey="success" stackId="a" fill="#00FF00" name="Success" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Box>
  );
};

InventoryGraph.propTypes = {
  pk: PropTypes.string,
  eventEmitter: PropTypes.object,
  setInventoryLoading: PropTypes.func,
  subscriberPk: PropTypes.any,
  getInventorySyncData: PropTypes.func,
};

export default InventoryGraph;
