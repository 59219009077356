// Lib Imports
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import { useToast } from 'granite-admin/core/components/Toast';
import { DataTable } from 'granite-admin/core/components/TableV3';

import { HISTORY_COLUMNS } from 'dashboard/controllers/constants';
import { getDateRangeOptions } from 'common/utility';

const filters = [
  {
    name: 'range',
    type: 'date_range',
    label: 'Date Range',
    customFormat: 'yyyy-MM-dd',
    viewDateFormat: 'yyyy-MM-dd',
    boundDays: 29,
    getDateRangeOptions: getDateRangeOptions,
  },
];

const HourlyTable = ({ eventEmitter, handleRetry, getHistory, handleCustomExport }) => {
  const { successToast, errorToast } = useToast();

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'REFRESH_SUCCESSS':
          successToast('REFRESH SUCCESSFUL');
          break;
        case 'FAILED_TO_REFRESH':
          errorToast('FAILED TO REFRESH');
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, successToast, errorToast]);

  return (
    <>
      <Box alignContent="center">
        {
          <DataTable
            columns={HISTORY_COLUMNS}
            actionIconColor="secondary"
            fetch={getHistory}
            filters={filters}
            searchDisabled={false}
            handleRetry={handleRetry}
            retryEventEmitter={eventEmitter}
            columnsHideable={false}
            handleCustomExport={handleCustomExport}
            withoutQuery
          />
        }
      </Box>
    </>
  );
};

HourlyTable.propTypes = {
  // add your proptypes here
  pk: PropTypes.string,
  eventEmitter: PropTypes.object,
  setHourlyLoading: PropTypes.func,
  subscriberPk: PropTypes.any,
  handleRetry: PropTypes.func,
  getHistory: PropTypes.func,
  handleCustomExport: PropTypes.func,
};

export default HourlyTable;
