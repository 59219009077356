import React, { useEffect, useState } from 'react';
import { Box, Button, TextInput, Text } from 'grommet';
import { Trash } from 'grommet-icons';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import Select from 'granite-admin/core/components/Select';
import FormField from 'granite-admin/core/components/FormField';
import { useToast } from 'granite-admin/core/components/Toast';
import Tooltip from 'granite-admin/core/components/Tooltip';

import sentralApi from 'sentral/gateways/sentral-api';
import konectApi from 'konect/gateways/konect-api';

const sharedStyle = css`
  &:hover {
    cursor: pointer;
  }
`;

const StyledTrash = styled(Trash)`
  ${sharedStyle}
`;

const CommonForm = ({
  initialValues,
  comp,
  source_profile_id,
  plugin_profile_pk,
  fetch,
  setRefreshStatus,
  refreshStatus,
}) => {
  const [campusOptions, setCampusOptions] = useState([]);
  const [studentNumberOptions, setStudentNumberOptions] = useState([]);
  const userProfile = useSelector(({ accounts }) => accounts.user.profile);
  const { errorToast, successToast } = useToast();
  const history = useHistory();

  const handleDelete = async tenant_pk => {
    try {
      if (tenant_pk) await konectApi.deleteMySettings(tenant_pk);
      successToast('Tenant deleted successfully!');
    } catch (e) {
      errorToast('Failed to delete!');
    }
  };

  const handleRefresh = async () => {
    try {
      const response = await sentralApi.refresh({
        subscriber_profile_pk: plugin_profile_pk,
      });
      setRefreshStatus('IN_PROGRESS');
      successToast(response?.message || 'Plugin Refreshing Started');
    } catch (e) {
      errorToast(e?.errors?.title || 'Unable to refresh plugin');
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await Promise.all([
          sentralApi.getCampusList(userProfile?.plugin_profile_pk),
          konectApi.getSettingMasterSettings({
            params: { codes: JSON.stringify(['SENTRAL_STUDENT_NUMBER']) },
          }),
        ]);
        let campusList = result[0].map(value => ({
          campus_id: value.pk,
          campus_name: value.name,
        }));
        setCampusOptions(campusList);

        result[1].forEach(data => {
          let arr = [];
          if (data.code === 'SENTRAL_STUDENT_NUMBER') {
            data.options.forEach(value => arr.push({ label: value, value: value }));
            setStudentNumberOptions(arr);
          }
        });
      } catch (e) {
        errorToast('Unable to load campus or student number!');
      }
    };
    fetch();
  }, [userProfile.plugin_profile_pk, errorToast]);

  const onSubmit = async (values, { setErrors, setSubmitting }) => {
    setErrors({});
    setSubmitting(true);
    try {
      let tenantObj = [];
      let flag = false;
      let tenantId = [];
      values.tenant.forEach((value, index) => {
        if (value.tenant) {
          tenantObj.push({
            tenant: value.tenant,
            pk: value.pk,
            extra_data: values?.campus[index] || {},
          });
          if (tenantId.indexOf(value.tenant) > -1) {
            setErrors({ [`tenant_${index}`]: ' Tenant already added above' });
            flag = true;
          }
          tenantId.push(value.tenant);
        } else {
          flag = true;
          setErrors({ [`tenant_${index}`]: 'Tenant is required' });
        }
      });

      if (flag) return;

      let val = {
        api_key: values.api_key,
        base_url: values.base_url,
        tenant: tenantObj,
        student_number: values.student_number,
      };
      if (comp === 'activate') {
        await sentralApi.register({ ...val, source_profile_id });
        successToast('Activated Successfully.');
        history.push('/sentral');
      } else if (comp === 'configuration') {
        await sentralApi.update({ ...val, source_profile_id });
        fetch();
        successToast('Configuration saved successfully.');
      }
    } catch (e) {
      errorToast(e?.errors?.title || 'Unable to save data!');
    }
    setSubmitting(false);
  };

  return (
    <Box
      pad="small"
      background="white"
      elevation="small"
      round="xsmall"
      border={{ color: 'brand', size: 'small', side: 'top' }}
      width="large"
      alignSelf="center"
      margin={{ top: '20px' }}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormField name="api_key" label="API Key" required error={touched.api_key && errors.api_key}>
                <TextInput
                  name="api_key"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.api_key}
                  size="small"
                />
              </FormField>
              <FormField name="base_url" label="Base Url" required error={touched.base_url && errors.base_url}>
                <TextInput name="base_url" onChange={handleChange} onBlur={handleBlur} value={values.base_url} />
              </FormField>

              <FormField
                name="student_number"
                label="Student Number"
                error={touched.student_number && errors.student_number}
              >
                <Select
                  name="student_number"
                  options={studentNumberOptions}
                  labelKey="label"
                  valueKey={{ key: 'value', reduce: true }}
                  value={values.student_number}
                  onBlur={handleBlur}
                  onChange={({ value }) => setFieldValue('student_number', value || '')}
                />
              </FormField>

              <FieldArray
                name="tenant"
                render={arrayHelpers => (
                  <Box>
                    {values?.tenant?.map((val, index) => (
                      <Box
                        key={val}
                        gap="xsmall"
                        border={{ size: 'xsmall' }}
                        margin={{ vertical: 'small' }}
                        direction="row"
                      >
                        <Box width="large" direction="column">
                          <FormField
                            name={`tenant_${index}`}
                            label="Tenant"
                            required
                            margin={{ left: 'small', right: 'small' }}
                            error={errors[`tenant_${index}`]}
                          >
                            <TextInput
                              name={`tenant_${index}`}
                              value={values.tenant[index].tenant}
                              onChange={({ target }) => {
                                setFieldValue(
                                  'tenant',
                                  values.tenant.map((ten, i) => (i !== index ? ten : { ...ten, tenant: target.value })),
                                );
                              }}
                            />
                          </FormField>
                          <FormField
                            name={`campus_${index}`}
                            label="Campus"
                            margin={{ left: 'small', right: 'small', bottom: 'small' }}
                            error={errors[`campus_${index}`]}
                          >
                            <Select
                              name={`campus_${index}`}
                              size="large"
                              options={campusOptions}
                              labelKey="campus_name"
                              valueKey={{ key: 'campus_id', reduce: false }}
                              value={values.campus[index]?.campus_id}
                              onChange={({ value }) => {
                                setFieldValue(
                                  'campus',
                                  values.campus.map((cam, i) => (i !== index ? cam : value)),
                                );
                              }}
                            />
                          </FormField>
                        </Box>
                        <Box width="xxsmall" alignSelf="start" pad={{ top: 'large' }}>
                          <StyledTrash
                            color="brand"
                            size="20px"
                            onClick={() => {
                              if (comp === 'configuration') handleDelete(values.tenant[index]?.pk);
                              arrayHelpers.remove(index);
                              setFieldValue('campus', moveItem(values.campus, index));
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                    <Button
                      primary
                      margin={{ top: 'small' }}
                      label="Add Tenant"
                      alignSelf="start"
                      color="brand"
                      onClick={() => {
                        arrayHelpers.push('');
                        setFieldValue('tenant', [...values.tenant, {}]);
                        setFieldValue('campus', [...values.campus, {}]);
                      }}
                    />
                  </Box>
                )}
              />
              <Box direction="row" justify={comp === 'configuration' ? 'between' : 'center'}>
                {comp === 'activate' ? (
                  <Box align="center" margin={{ top: 'small' }}>
                    <Button type="submit" align="end" label="ACTIVATE" />
                  </Box>
                ) : !_.isEqual(initialValues, values) ? (
                  <Box align="end" margin={{ top: 'medium' }}>
                    <Button type="submit" label={isSubmitting ? 'Saving...' : 'Save'} disabled={isSubmitting} primary />
                  </Box>
                ) : null}
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

const moveItem = (arr, from, to) => {
  const data = [...arr];
  var item = data.splice(from, 1)[0]; // deletion
  if (to !== undefined) data.splice(to, 0, item); // insertion
  return data;
};

const validationSchema = Yup.object().shape({
  api_key: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('API Key is required'),
  base_url: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Base Url is required'),
  student_number: Yup.string().required('Student Number is required'),
});

CommonForm.propTypes = {
  initialValues: PropTypes.object,
  comp: PropTypes.string,
  source_profile_id: PropTypes.any,
  fetch: PropTypes.func,
  plugin_profile_pk: PropTypes.any,
  refreshStatus: PropTypes.string,
  setRefreshStatus: PropTypes.func,
};
export default CommonForm;
