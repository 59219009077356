class Dashboard {
  constructor(data) {
    this.pk = data.pk;
    this.hourlyProgress = data.date;
    this.inProgress = data.in_progress;
    this.success = data.success;
    this.failed = data.failed;
  }
}

export default Dashboard;
