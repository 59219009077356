import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';

/* eslint-disable */
const URL = process.env.REACT_APP_SENTRAL_BASE_URL;
const SYNC_URL = process.env.REACT_APP_SENTRAL_SYNC_URL;
const CAMPUS_URL = process.env.REACT_APP_CAMPUS_LIST_URL;

async function register(params) {
  try {
    const { data } = await GraniteAPIClient(URL).post(`/register/`, params);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function update(params) {
  try {
    const { data } = await GraniteAPIClient(URL).post(`/update/`, params);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function handleRetry(params) {
  try {
    const { data } = await GraniteAPIClient(SYNC_URL).post(`/reattempt_sync/`, params);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getCampusList(id) {
  try {
    const { data } = await GraniteAPIClient(CAMPUS_URL).get(`campus_list?subscriber_profile_pk=${id}`);
    return data.results;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

export default { register, update, handleRetry, getCampusList };
