// Core Imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';

/* eslint-disable */
import DashboardEntity from 'dashboard/entities/dashboard';

const URL = process.env.REACT_APP_AGGREGATE_BASE_URL;

async function getInventorySyncData(params) {
  const payload = { data: { ...params } };
  try {
    const { data } = await GraniteAPIClient(URL).post('/report/1/result/', payload);
    const list = data.results.map(item => new DashboardEntity(item));
    return { list, count: data.count };
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}
export default { getInventorySyncData };
