/* eslint-disable react/display-name */
/* eslint react/prop-types: 0 */
import React from 'react';
import { Text, Box } from 'grommet';
import { StatusGood, Sync } from 'grommet-icons';

import Tooltip from 'granite-admin/core/components/Tooltip';

const HISTORY_COLUMNS = [
  {
    accessor: 'pk',
    id: 'pk',
    Header: 'ID',
    Cell: ({ cell: { value, row }, ...rest }) => <Text>{value}</Text>,
  },
  {
    id: 'created_at',
    accessor: 'date',
    Header: 'DATE',
    Cell: ({ cell: { value, row }, ...rest }) => <Text>{value.replace('T', ' ').slice(0, value.indexOf('.'))}</Text>,
  },
  {
    id: 'status',
    accessor: 'status',
    Header: 'STATUS',
    Cell: ({ cell: { value, row }, ...rest }) => <Text>{value}</Text>,
  },
  {
    id: 'error_details',
    accessor: 'errorStatus',
    Header: 'ERROR DETAILS',
    Cell: ({ cell: { value, row }, ...rest }) => <Text>{value}</Text>,
    disableSortBy: true,
  },
  {
    Header: 'RETRY',
    Cell: ({ cell: { value, row }, handleRetry, retryEventEmitter, ...rest }) => (
      <Box margin="xsmall">
        {row.original.status !== 'Success' ? (
          <Tooltip align="top" content="Click to retry">
            <Sync onClick={() => handleRetry(row.original.pk, retryEventEmitter)} color="red" />
          </Tooltip>
        ) : (
          <StatusGood color="green" />
        )}
      </Box>
    ),
    disableSortBy: true,
  },
];

export { HISTORY_COLUMNS };
